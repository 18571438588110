<template>
  <div>
    <v-card-text>
      <v-container class="row1">
        <v-row>
          <v-col cols="14" sm="8" md="8">
            <v-row dense class="mb-5">
              <router-link
                v-bind:to="{
                  path: '/assets-invoice/assets-stock/' + assetInfo.assetStockId
                }"
                style="text-decoration: none;"
              >
                <v-btn rounded color="#05a2fa">
                  <v-icon color="white">
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </router-link>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="4" md="8" class="mx-auto">
            <v-card>
              <div style="float:right; margin-top:10px; margin-right:10px;">
                <v-icon
                  color="#05a2fa"
                  @click="editItem()"
                  style="margin-right:5px"
                >
                  mdi-pencil-outline
                </v-icon>
                <v-icon color="#FF0000" @click="deleteItem()">
                  mdi-trash-can-outline
                </v-icon>
              </div>
              <v-card-title>
                AssetCode:
                {{ assetInfo.assetCode }}<br />

                <!-- Asset Id : {{ assetInfo.assetId }} <br />
                
                assetTypeId: {{ assetInfo.assetTypeId }}<br />
                locationId:
                {{ assetInfo.locationId }} -->
              </v-card-title>
              <v-card-title style="font-size:20px">
                Asset Name : {{ assetInfo.productName }} Null
              </v-card-title>

              <v-divider class="mx-4"></v-divider>

              <v-card-text>
                EAN : {{ assetInfo.ean }} <br />
                condition :  
                
                <span v-if="assetInfo.condition === 0" class="red--text">
                  Bad
                </span>
                <span v-if="assetInfo.condition === 1" class="green--text">
                  Good
                </span>
                <br />

                Stock Available :

                <span v-if="assetInfo.status === 0" class="red--text">
                  No
                </span>
                <span v-if="assetInfo.status === 1" class="green--text">
                  Yes
                </span>
                <br />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="dialogEdit" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Asset </span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="AssetForm">
                <v-row>
                  <!-- <v-col cols="12">
                    <v-text-field
                      v-model="editedAssetInfo.assetCode"
                      label="assetCode"
                      outlined
                      clearable
                    />
                  </v-col> -->
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedAssetInfo.ean"
                      label="EAN "
                      maxlength="13"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="editedAssetInfo.status"
                      label="Available"
                      :items="status"
                      item-text="text"
                      item-value="value"
                      outlined
                       
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="editedAssetInfo.condition"
                      label="Condition"
                       :items="condition"
                      item-text="text"
                      item-value="value"
                      outlined
                      
                    />
                  </v-col>

                  <!-- <v-col cols="12">
                    <v-text-field
                      v-model="editedAssetInfo.assetTypeId"
                      item-text="assetTypeId"
                      item-value="assetTypeId"
                      label="assetTypeId"
                      outlined
                      clearable
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="editedAssetInfo.locationId"
                      item-text="locationId"
                      item-value="locationId"
                      label="locationId"
                      outlined
                      clearable
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="editedAssetInfo.assetStockId"
                      :items="status"
                      item-text="assetStockId"
                      item-value="assetStockId"
                      label="Asset Stock Id"
                      outlined
                      clearable
                    />
                  </v-col> -->
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="dialogEdit = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveEdit"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to delete this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="trashItem">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </div>
</template>

<style>
.row1 {
  margin-top: -25px;
}
.inputNumber input[type="number"] {
  -moz-appearance: textfield;
}
.inputNumber input::-webkit-outer-spin-button,
.inputNumber input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>

<script>
export default {
  data() {
    return {
      dialogEdit: false,
      dialogDelete: false,
      dialogRestore: false,
      dialogTrash: false,
      search: "",

      customerAccounts: [],
      guests: [],

      status: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 }
      ],
      condition: [
        {text : "Good", value: 1 },
        {text : "Bad", value:0}
      ],

      editedIndex: -1,
      editedAssetInfo: {
        assetCode: "",
        ean: "",
        status: "",
        condition: "",
        assetTypeId: "",
        locationId: "",
        assetStockId: ""
      },

      assetInfo: {}
    };
  },

  watch: {
    dialogEdit(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  created() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      var currentUrl = window.location.pathname;
      let vars = currentUrl.split("/");
      vars.reverse();
      let url = vars[0];
      url = parseInt(url);

      this.$http.get("/WristBand").then(response => {
        this.wristbands = response.data.data;
      });
      this.$http.get("/CustomerAccount").then(response => {
        this.customerAccounts = response.data.data;
      });
      this.$http.get("/Guest").then(response => {
        this.guests = response.data.data;
      });
      this.$http.get(`/Asset/${url}`).then(response => {
        this.assetInfo = response.data.data;
      });
    },

    editItem() {
      this.editedAssetInfo = Object.assign({}, this.assetInfo);
      this.dialogEdit = true;
    },

    deleteItem() {
      this.dialogDelete = true;
    },

    trashItem() {
      this.$http
        .post(`/Asset/Trash?Id=${this.assetInfo.assetId}`)
        .then(response => {
          if (response.data.success === true) {
            this.$toast.success("Deleted successfully.", "Success", {
              position: "topRight"
            });
            this.$router.push(
              `/assets-invoice/assets-stock/${this.assetInfo.assetStockId}`
            );
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast.error(" Deleting Process Failed", "Error", {
              position: "topRight"
            });
          }
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedAssetInfo = Object.assign({}, this.defaultProduct);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedAssetInfo = Object.assign({}, this.defaultProduct);
        this.editedIndex = -1;
      });
    },

    saveEdit() {
      if (this.$refs.AssetForm.validate()) {
        var Id = this.assetInfo.productId;
        this.$http
          .put("/Product/" + Id, this.editedAssetInfo)
          .then(response => {
            if (response.data.success === true) {
              this.getDataFromApi();
              this.$toast.success("Product updated successfully.", "Success", {
                position: "topRight"
              });
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight"
              });
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.$toast.error(" Updating Process Failed", "Error", {
                position: "topRight"
              });
            }
          });
        this.dialogEdit = false;
      }
    },

    getStatusColor(data) {
      switch (data) {
        case "true":
          return "success";
        case "false":
          return "error";
        default:
          return "";
      }
    }
  }
};
</script>
